import { inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// TODO: Change this to use cookies/session or something like that
@Injectable({
  providedIn: "root"
})
export class UserService {
  protected http = inject(HttpClient);

  public isLogged = signal<boolean>(false);

  public setUserToken(token: string) {
    localStorage.setItem("user-token", token);
  }

  public getUserToken() {
    return localStorage.getItem("user-token");
  }

  public getUserData() {
    const userData = localStorage.getItem("user-data");
    if (!!userData) {
      return JSON.parse(userData);
    }

    return null;
  }

  public setUserData() {
    this.getUserDetails().subscribe({
      next: (res: any) => {
        localStorage.setItem("user-data", JSON.stringify(res?.body?.data));
      },
      error: (err: any) => {
        console.error("Problem while getting user details", err);
      }
    });
  }

  public getUserDetails() {
    return this.http.get("/appUser/details", {
      observe: "response",
    });
  }

  // public getUserDetailsById(userId: number) {
  //   const queryParams = new HttpParams()
  //     .set("AppUserId", userId);
  //
  //   return this.http.get("/appUser/details", {
  //     observe: "response",
  //     params: queryParams
  //   });
  // }

  public getUserDetailsById(id: number) {
    const queryParams = new HttpParams()
      .set("Id", id)

    return this.http.get("/appUser/detailsById", {
      params: queryParams,
      observe: "response",
    });
  }

  public deleteUser(id: number) {
    return this.http.delete(`/appUser/${id}`, {
      observe: "response"
    })
  }

}
