import { inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginFormValues, RegisterFormValues } from '../../../../shared/types/form.types';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../../../../shared/services/user/user.service';
import { TOAST_LIFE_TIME } from '../../../../config/app.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected http = inject(HttpClient);
  protected router = inject(Router);
  protected messageService = inject(MessageService);
  protected userService = inject(UserService);

  public register(registerData: RegisterFormValues) {
    return this.http.post(`/appUser/register`, registerData, {
      observe: "response"
    });
  }

  public login(loginData: LoginFormValues) {
    const test = {
      login: loginData.email,
      password: loginData.password,
    }

    // return this.http.post(`/appUser/login`, loginData);
    return this.http.post(`/appUser/login`, test, {
      observe: "response"
    });
  }

  public logout(): void {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: "Wylogowano",
      life: TOAST_LIFE_TIME,
    });
    localStorage.clear();
    this.userService.isLogged.set(false);
    this.router.navigate(["/"]);
  }

}
